<div>
    <div class="modal-header bg-blue-dark text-white">
        {{ translations.addPersonalDetails }}
    </div>
    <form [formGroup]="form" (ngSubmit)="submit()">
        <div class="modal-body bg-ecru p-4">
            <p>{{ translations.addPersonalDetailsDescription }}</p>

            <hr />

            <div class="form-group" *ngIf="!(this.userInfo.email || this.userInfo.emailEnriched)">
                <label for="email">{{ translations.emailLabel }}</label>
                <input [required]="!form.value.phone" type="email" name="email" class="form-control" formControlName="email"
                    [autocomplete]="'email'"
                    [placeholder]="translations.emailPlaceholder"
                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    {{ translations.validEmail }}
                </div>
            </div>

            <div class="form-group" *ngIf="!(this.userInfo.phone || this.userInfo.phoneEnriched)">
                <label for="phone">{{ translations.phoneLabel }}</label>
                <input [required]="!form.value.email" type="phone" name="phone" class="form-control" formControlName="phone"
                    [autocomplete]="'tel'"
                    [placeholder]="translations.phonePlaceholder"
                    [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" />
                <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                    {{ translations.validPhone }}
                </div>
            </div>

            <div class="form-group bg-ecru-dark-25 px-3 py-2">
                <div class="custom-control custom-checkbox">
                    <input type="checkbox" formControlName="acceptTerms" id="acceptTerms"
                        class="custom-control-input cursor-pointer"
                        [ngClass]="{ 'is-invalid': submitted && f.acceptTerms.errors }">
                    <label class="custom-control-label font-weight-normal cursor-pointer" for="acceptTerms">
                        {{ translations.privacyConsent }}
                        <a href="https://www.infino.be/privacy">{{ translations.privacyConsentUrl }}</a>
                    </label>
                    <div *ngIf="submitted && f.acceptTerms.errors" class="invalid-feedback">
                        {{ translations.privacyConsentRequired }}
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex bg-white p-4">
            <button type="button" class="btn btn-outline-primary w-100 mr-3" (click)="dismiss()">{{ translations.skip
                }}</button>
            <button type="submit" class="btn btn-primary w-100">{{ translations.addData }}</button>
        </div>
    </form>
</div>
