import { ChildBenefitsApiService } from './../../../../../core/files/services/child-benefits-files-api.service';
import { ValidationHelper } from './../../../../../shared/infrastructure/validation/validation.helper';
import { ModifyUserDataModalModel } from './modify-user-data-modal.model';
import { ModifyUserDataModalService } from './modify-user-data-modal.service';
import { Component, OnInit } from '@angular/core';
import { PortalUserService } from 'src/app/core/portal-user/services/portal-user.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export enum PaymentOptions {
  AccountNumberMotherCheck = 'accountNumberMotherCheck',
  AccountNumberBothCheck = 'accountNumberBothCheck',
}

@Component({
  selector: 'app-modify-user-data-modal',
  templateUrl: './modify-user-data-modal.component.html',
})
export class ModifyUserDataModalComponent implements OnInit {
  model: ModifyUserDataModalModel;
  validationErrors: { [key: string]: string[] | undefined } = {};
  public hasGeneralError: boolean;
  private selectedRadioBtn: string;

  constructor(
    private service: ModifyUserDataModalService,
    private activeModal: NgbActiveModal,
    private portalUserService: PortalUserService,
    private childBenefitsApiService: ChildBenefitsApiService,
  ) { }

  ngOnInit(): void {
    this.model = this.service.getModel();
  }

  public set selectedRadio(value: string) {
    this.selectedRadioBtn = value;
    this.radioChanged(value);
  }
  public get selectedRadio(): string {
    return this.selectedRadioBtn;
  }

  public radioChanged(newValue: string) {
    this.model.form.bankAccountMotherCheck = newValue === PaymentOptions.AccountNumberMotherCheck;
    this.model.form.bankAccountBothCheck = newValue === PaymentOptions.AccountNumberBothCheck;
  }

  public validateItem(fieldName: string) {
    const validationResult = ValidationHelper.validateField(
      fieldName,
      this.model.getValidators()
    );
    this.validationErrors = validationResult.extendErrorObject(
      this.validationErrors
    );
  }

  public resetItemValidation(fieldName: string) {
    this.validationErrors = ValidationHelper.removeFromErrorObject(fieldName, this.validationErrors);
  }

  public close() {
    this.activeModal.dismiss();
  }

  public onSubmit() {
    const validationResult = ValidationHelper.validate(this.model.getValidators());
    if (!validationResult.isValid) {
      this.validationErrors = validationResult.getErrorObject();
      return;
    }

    const updatePhoneOrEmail = this.model.form.phoneEnabled || this.model.form.emailEnabled;

    // if nothing is checked / changed, do nothing and close modal
    if (!this.model.form.bankAccountEnabled && !updatePhoneOrEmail) {
      this.close();
      return;
    }

    //if data is still invalid, do not proceed!
    if ((this.model.form.bankAccountEnabled && !this.model.form.bankAccountMotherCheck && !this.model.form.bankAccountBothCheck)
      || (this.model.form.emailEnabled && !this.model.form.email)
      || (this.model.form.phoneEnabled && !this.model.form.phone)) {
      return;
    }

    if (this.model.form.loading) {
      return;
    }

    this.model.form.loading = true;

    if (this.model.form.bankAccountEnabled) {
      this.portalUserService
        .updateUserBankAccountNumber({
          region: this.model.activeRegion,
          fileId: this.model.activeFileId,
          bankAccountNumber: this.model.form.bankAccountMotherCheck ? this.model.form.bankAccountNumberMother : this.model.form.bankAccountNumberBoth,
          bankAccountType: this.model.form.bankAccountMotherCheck ? 'Mother' : 'Both',
          bankAccountHasConsent: this.model.form.bankAccountMotherCheck ? this.model.form.bankAccountMotherHasConsent : this.model.form.bankAccountBothCheck,
          personFullName: this.model.personFullName
        })
        .subscribe((result) => {
          if (updatePhoneOrEmail) {
            //do not show yet confirmation since we also will update email and or phone
            return;
          }

          this.model.form.loading = false;
          this.model.form.result = result;
          if ((this.model.form.result.Results || []).every(x => x.IsSuccess)) {
            this.model.form.success = true;
            this.refreshData();
          }
        }, error => {
          this.model.form.success = false;
          this.model.form.loading = false;
          this.hasGeneralError = true;
          this.refreshData();
        });
    }


    if (updatePhoneOrEmail && (this.model.form.email || this.model.form.phone)) {
      this.portalUserService
      .updateUserInfo({ email: this.model.form.email, phone: this.model.form.phone, regions: this.model.regions })
      .subscribe((result) => {
          this.model.form.loading = false;
          this.model.form.result = result;
          if ((this.model.form.result.Results || []).every(x => x.IsSuccess)) {
            this.model.form.success = true;
            this.refreshData();
          }
        }, error => {
          this.model.form.success = false;
          this.model.form.loading = false;
          this.hasGeneralError = true;
          this.refreshData();
        });
    }
  }

  private refreshData() {
    this.portalUserService.refetch();
    this.childBenefitsApiService.initialize();
  }
}
