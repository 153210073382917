import {
  Component,
  EventEmitter,
  forwardRef,
  HostListener,
  Output,
  ElementRef,
  ViewChild,
  Input,
  Injectable,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { TwoWayBindingInputComponent } from '../two-way-binding-input.component';
import { DateFormatHelper } from '../../../helpers/date-format.helper';
import { ValidationHelper } from '../../../infrastructure/validation/validation.helper';
import {
  NgbCalendar,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import * as dayjs from 'dayjs';
const customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);

@Component({
  selector: 'app-datepicker-input',
  templateUrl: './datepicker-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerInputComponent),
      multi: true,
    },
  ],
})
export class DatepickerInputComponent extends TwoWayBindingInputComponent<string> {
  @ViewChild('input') public input: ElementRef;

  private readonly INPUT_FORMAT = 'YYYY-MM-DD';
  private readonly VALUE_FORMAT = 'DD/MM/YYYY';

  public valueChanged($event: string) {
    this.value = $event;
    this.propagateChange($event);
    this.startValidation();
  }

  public get dateValue(): string {
    if (!this.value) {
      return '';
    }

    const dateInputFormat = dayjs(this.value, this.INPUT_FORMAT);
    if (dateInputFormat.isValid()) {
      this.valueChanged(dateInputFormat.format(this.VALUE_FORMAT));
      return dateInputFormat.format(this.INPUT_FORMAT);
    }

    const dateValueFormat = dayjs(this.value, this.VALUE_FORMAT, true);
    if (dateValueFormat.isValid()) {
      return dateValueFormat.format(this.INPUT_FORMAT);
    }

    return '';
  }

  public dateValueChanged($event: string) {
    this.resetValidation();

    if ($event) {
      const date = dayjs($event, this.INPUT_FORMAT, true);
      if (date.isValid()) {
        this.valueChanged(date.format(this.VALUE_FORMAT));
      }
    } else {
      this.valueChanged($event);
    }
  }
}
